export const image = {
    google: require('./google.png'),
    hide: require('./hide.png'),
    view: require('./view.png'),
    later: require('./later.png'),
    now: require('./now.png'),
    success: require('./success.png'),
    apple: require('./apple.png'),
    google: require('./google.png'),
    view: require('./view.png'),
    hide: require('./hide.png'),
    sample: require('./sample.jpg'),
    menu: require('./menu.png'),
    user: require('./user.png'),
    close: require('./close.png'),
    book: require('./book.png'),
    logo: require('./logo.png'),
    instagram: require('./instagram.png'),
    www: require('./www.png'),
    email: require('./email.png'),
    right: require('./right-arrow.png'),
    pen: require('./noto_pen.png'),
    mastercard: require('./Mastercard.png'),
    visa: require('./visa.png'),
    apay: require('./apay.png')
}
